$smootheaseout: .62,
.02,
.34,
1;






.logo-text-animation {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    .img-roate {
        position: absolute;
        width: 1rem;
        animation: toorigin 2s linear, hide 8s linear;
        animation-fill-mode: forwards;


        &:nth-child(1) {
            transform: rotate(40deg);
            left: 2%;
            top: 88%;
        }

        &:nth-child(2) {
            transform: rotate(0deg);
            left: 0%;
            top: .4rem;
            transform: rotate(180deg);
        }

        &:nth-child(3) {
            transform: rotate(-19deg);
            left: 80%;
            top: 5rem;


        }

        &:nth-child(4) {
            transform: rotate(-31deg);
            left: 33%;
            top: .1rem;


        }

        &:nth-child(5) {
            transform: rotate(-315deg);
            left: 90%;
            top: 40%;
            transform: rotate(300deg);


        }

        &:nth-child(6) {
            transform: rotate(0deg);
            left: 90%;
            top: 0;
            transform: rotate(80deg);



        }
    }

}



@keyframes shwo {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.qi-img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 2.2rem;
    height: auto;
    opacity: 1;
    animation: hide 1.5s linear;
    animation-fill-mode: forwards;
    // animation-delay: 1.5s;
    cursor: pointer;

}



.gray-text {
    font-family: Posterama1927-Black;
    font-size: .2rem;
    color: #777777;
    text-align: center;
    line-height: .28rem;
}

.red-text {
    font-family: Posterama1927-Black;
    font-size: .2rem;
    color: #D9052D;
    text-align: center;
    line-height: .28rem;
    line-height: .28rem;

}

.bold-white-text {
    font-family: Posterama1927-Black;
    font-size: .3rem;
    color: #FFFFFF;
    text-align: center;
    line-height: .42rem;
    margin-top: .1rem;
}

.box-link {
    position: relative;
    display: inline-block;
    color: #fff;
    background: url('../../images/red-texture.png') repeat center center / 1rem 1rem;
    border-radius: 1.6667rem;
    cursor: pointer;

    span {
        z-index: 10;
        position: relative;
    }

    &::after {
        content: "";
        position: absolute;
        z-index: 1;
        border-radius: 1.6667rem;
        background: #1a1a1d;
        bottom: .03rem;
        top: .03rem;
        right: .03rem;
        left: .03rem;
        transition: .18s  cubic-bezier($smootheaseout);
    }

    &:hover {
        &::after {
            bottom:.08rem;
            top:.08rem;
            right:.08rem;
            left:.08rem;
        }
    }
}

.btn {
    // border: 3px solid #D9052D;
    border-radius: .42rem;
    font-family: Posterama1927-Black;
    font-size: .3rem;
    color: #FFF8F7;
    display: flex;
    padding: .21rem 1.05rem;
    margin-top: .34rem;
}

// 

.completed-box {
    display: flex;
    align-items: center;
    justify-content: center;

    .logo{
        width: 9.84rem;
    }

}

.completed-view {
    height: 100%;
}

.home-text {
    opacity: 0;
    animation: 1.5s show;
    animation-fill-mode: forwards;

}





@keyframes scratchy {
    0% {
        background-position: 0 0;
    }

    25% {
        background-position: 0 0;
    }

    26% {
        background-position: .2rem -0.2rem;
    }

    50% {
        background-position: .2rem -0.2rem;
    }

    51% {
        background-position: .4rem -0.4rem;
    }

    75% {
        background-position: .4rem -0.4rem;
    }

    76% {
        background-position: .6rem -0.6rem;
    }

    99% {
        background-position: .6rem -0.6rem;
    }

    100% {
        background-position: 0 0;
    }
}


@keyframes toorigin {


    to {
        width: 2rem;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) rotate(0);

    }
}


@keyframes hide {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        pointer-events: none;

    }
}

@keyframes show {
    to {
        opacity: 1;
    }
}

// 渐变

@keyframes shard-flow {
    0% {
        background-color: rgba(0, 0, 0, 0);
    }

    30% {
        background-color: rgba(255, 0, 0, .47);
    }

    66% {
        background-color: rgba(0, 0, 0, .1);
    }

    100% {
        background-color: rgba(0, 0, 0, 0);

    }
}
