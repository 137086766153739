
html,body{
    height: 100%;
    width:100%;
    min-width:320px; // 可自行调整最小宽度
  }

@mixin reset {
    padding: 0;
    margin: 0;
}
$baseDevice: 414; // 默认iphone6设计稿
$device: $baseDevice / 2; // 375 ，iphone6设备宽度
$baseFontSize: 100px;
@function px2rem($px, $base-font-size: $baseDevice / $device * $baseFontSize) {
  // unitless() 函数只是用来判断一个值是否带有单位，如果不带单位返回的值为 true，带单位返回的值为 false
  // unit() 函数主要是用来获取一个值所使用的单位，碰到复杂的计算时，其能根据运算得到一个“多单位组合”的值，不过只充许乘、除运算
  @if (unitless($px)) { 
    @warn 'Assuming #{$px} to be in pixels';
    @return px2rem($px + 0px)
  }
  @else if (unit($px) == rem) { 
    @return $px
  }
  @return ($px / $base-font-size) * 1rem
}

html {
  font-size: $baseFontSize;
}
@media screen and (min-width: 320px) {
  html {
    font-size: (320 /  $device) * $baseFontSize;
  }
}
@media screen and (min-width: 360px) {
  html {
    font-size: (360 /  $device) * $baseFontSize; // 96px
  }
}
@media screen and (min-width: 375px) {
  html {
    font-size: (375 /  $device) * $baseFontSize; // 100px
  }
}
@media screen and (min-width: 414px) {
    html {
      font-size: (414 /  $device) * $baseFontSize;
    }
  }
@media screen and (min-width: 480px) {
  html {
    font-size: (480 /  $device) * $baseFontSize; // 128px
  }
}
@media screen and (min-width: 640px) {
  html {
    font-size: (640 /  $device) * $baseFontSize;
  }
}
@media screen and (min-width: 750px) {
  html {
    font-size: (750 /  $device) * $baseFontSize;
  }
}


// 示例使用
.mock {
  font-size: px2rem(16px); // 750px的设计稿量出来是多少px就直接扔进去自动换算为rem
}


.App.mobile {   

    // 按钮
    .home {
        height: auto;
        display: block;
        .round-box{
            left: 50%;
            top: px2rem(200px);
            transform: translateX(-50%);
            .round-img{
                width: px2rem(180px);
            }
        }
        .completed-view {
            padding-top: px2rem(200px);

            .completed-box {
                padding-bottom: px2rem(20px);
                width: px2rem(235px);
                height: px2rem(99px);
                .logo{
                    width: 100%;
                }
            }

            .home-text {
                text-align: center;
                max-width: px2rem(336px);


                .gray-text {
                    font-family: Posterama1927-Black;
                    font-size: px2rem(14px);
                    color: #777777;
                    text-align: center;
                    line-height: px2rem(19px);
                }

                .red-text {
                    font-family: Posterama1927-Black;
                    font-size: px2rem(14px);
                    color: #D9052D;
                    text-align: center;
                    line-height: px2rem(19px);
                }

                .bold-white-text {
                    font-family: Posterama1927-Black;
                    font-size: px2rem(22px);
                    color: #FFFFFF;
                    text-align: center;
                    line-height: px2rem(30px);
                    margin-top: px2rem(13px);
                    margin-bottom: px2rem(30px);
                }

                .btn.box-link {
                    @include reset();
                    background: url('../../src/images/red-texture.png') repeat 50% center;
                    background-size: px2rem(60px) px2rem(60px);
                    display: inline-block;
                    padding-top: px2rem(16px);
                    padding-left: px2rem(79px);
                    padding-bottom: px2rem(16px);
                    padding-right: px2rem(79px);
                    font-family: Posterama1927-Black;
                    font-size: px2rem(18px);
                    color: #FFF8F7;
                    border: px2rem(1px) solid #D9052D;
                    border-radius: px2rem(42px);
                    margin: 0 auto;
                    &::after{
                        content: "";
                        position: absolute;
                        z-index: 1;
                        background: #1a1a1d;
                        bottom: px2rem(0.8px);
                        top: px2rem(1px);
                        right: px2rem(1px);
                        left: px2rem(1px);
                        transition: 0.18s 
                    }
                }

            }
            .home-text.pc{
                display: none;
            }
            .home-text.wap{
                display: flex;
                flex-direction: column;
            }
            
        }

    }

    // 详情

    .whole-page {
        width: 100%;
        overflow: hidden;
        background-color: #1A1A1D;
        ;

        .lef-nav-box,
        .right-nav-box {
            top: px2rem(20px);
            display: flex;
            margin: 0;
            padding-top: px2rem(20px);
            transform:none;
            .nav-item {
                @include reset();
                width: px2rem(51px);
                height: px2rem(51px);
            }

        }
        .lef-nav-box {
            left: px2rem(20px);
            .nav-item {
                margin-right: px2rem(14px);
                &:last-child {
                    display: block;
                    position: fixed;
                    bottom: px2rem(10px);
                    right: px2rem(20px);
                    z-index: 999;
                    bottom: px2rem(30px);
                    right: px2rem(20px);
                }
            }

        }

        .right-nav-box {
            right: px2rem(20px);

            .nav-item {
                margin-left: px2rem(14px);
            }
        }

        .content-item {
            overflow: scroll;

            .logo-icon-small {
                display: none;
            }
            .main-view{
                .item-one {
                    padding: 0;
                    display: flex;
                    align-items: normal;
                    padding-top: px2rem(122px);
                    box-sizing: border-box;
    
                    .wrap {
                        width: 100%;
    
                        .bg {
                            width: px2rem(351px);
                            height: auto;
                            left: px2rem(32px);
                            top: px2rem(20px);
                            position: absolute;
                        }
    
                        &>.flex {
                            padding-left: px2rem(148px);
                            height: fit-content;
                            position: relative;
                            top: px2rem(80px);

                            .so {
                                position: absolute;
                                left: px2rem(32px);
                                bottom: 0;
                                transform: translateY(40px);
                                font-family: PosteramaText-SemiBold;
                                font-size: px2rem(80px);
                                color: #FFFFFF;
                            }
    
                            .the {
                                font-family: PosteramaText-Regular;
                                font-size: px2rem(16px);
                                color: #FFFFFF;
                                line-height: px2rem(32px);
                                padding: 0;
                                margin: 0;
                                width: auto;
                                padding-right: px2rem(20px);
                            }
    
                        }
    
                        .here {
                            @include reset();
                            padding-top: px2rem(140px);
                            text-align: center;
                            font-family: PosteramaText-SemiBold;
                            font-size: px2rem(40px);
                            color: #FFFFFF;
                            line-height: px2rem(40px);
                            display: block;
                        }
    
                        .red-text {
                            @include reset();
                            text-align: center;
                            font-family: PosteramaText-SemiBold;
                            font-size: px2rem(40px);
                            color: #D9052D;
                            height: px2rem(43px);
                            line-height: px2rem(43px);
                            padding-top: px2rem(10px);
                            padding-bottom: px2rem(100px);
                        }
                    }
                }
    
                .item-two {
                    @include reset();
                    width: 100%;
                    padding-top: px2rem(166px);
                    padding-left: px2rem(49px);
                    box-sizing: border-box;
                    padding-bottom: px2rem(125px);
    
                    .box {
                        flex-direction: column;
                        width: 100%;
    
                        .left {
                            flex-direction: column;
                            width: 100%;
                            .dou {
                                font-size: px2rem(300px);
                                color: #D9052D;
                                left: px2rem(-55px);
                                top: px2rem(55px);
                            }
    
                            .the {
                                font-family: PosteramaText-Regular;
                                @include reset();
                                line-height: px2rem(42px);
                                font-size: px2rem(26px);
                                color: #FFFFFF;
                            }
    
                            .dou-text {
                                padding-right: px2rem(20px);
                            }
    
                            .it {
                                @include reset();
                                font-family: PosteramaText-SemiBold;
                                font-size: px2rem(40px);
                                color: #FFFFFF;
                                line-height: px2rem(50px);
                                padding-right: px2rem(36px);
                                padding-bottom: px2rem(43px);
                                padding-top: px2rem(30px);
                            }
                        }
                        .grid-box.wap {
                            .right {
                                &.one,&.two{
                                    .grid-border {
                                        @include reset();
                                        border-radius: px2rem(9.52px) !important;
                                        // padding: px2rem(1.12px) !important;
                                        background-size:px2rem(114px) 100%;
                                        width: px2rem(114px);
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        margin-bottom: px2rem(34.47px);
                                        position: relative;
                                        background-image: linear-gradient(#2D2D2D, #FFFFFF);
                                        
                                        .grid {
                                            border-radius: px2rem(9.52px) !important;
                                            width: px2rem(88.61px) !important;
                                            padding: px2rem(10px) !important;
                                            font-family: PosteramaText-Regular;
                                            font-size: px2rem(8px);
                                            color: #FFFFFF;
                                            transform: translateY(-1px);
                                            background-color: #1a1a1d;

                                        }
        
                                        &.end {
                                            margin-top: px2rem(25.14px);
                                        }
                                        &:nth-child(1){
                                            &::after{
                                                transform: translateY(-100%) rotate(0deg);

                                            }
                                        }
                                     
                                        &::after{
                                            display: block;
                                            content: '';
                                            width: px2rem(19.07px)!important;
                                            height: px2rem(10.62px)!important;
                                            position: absolute;
                                            bottom: -37.47px !important;
                                            transform: translateY(-100%);
                                            background: url('../images/icon/arrow-bottom.png') no-repeat;
                                            background-size: 100% 100% !important;
                                        }
                                      
        
                                    }     
                                }
                             
    
                                .grid-arrow {
                                    // background-size: px2rem(19.07px) px2rem(10.62px) !important;
                                    width: px2rem(19.07px)!important;
                                    height: px2rem(10.62px)!important;
                                }
    
                                .grid-arrow-spec {
                                    width: px2rem(35.89px) !important;
                                    height: px2rem(99.24px) !important;
                                    transform: translateX(px2rem(-28px)) !important;
                                    bottom: px2rem(71.24px);
                                    left: px2rem(-11px);
                                }
    
    
                            }
                            .right.one{
                                margin: 0;
                            }
                            .right.one {
                                .grid-border:last-child {
                                    margin-bottom: 0;
                                    &::after{
                                        content: none;
                                    }
                                }
                            }
                            .right.two{
                                .grid-border:nth-last-child(1),
                                .grid-border:nth-last-child(2){
                                    margin-bottom: 0;
                                    &::after{
                                        content: none;
                                    }
                                
                                }
                            }
                            .grid-middle {
                                width: px2rem(41.96px);
                                position: relative;
                                &::before{
                                    display: block;
                                    content: '';
                                    width: px2rem(19.07px);
                                    height:px2rem( 10.62px);
                                    position: absolute;
                                    top: px2rem(54.47px);
                                    left: 50%;
                                    transform: translate(-50%,-100%) rotate(-90deg);
    
                                    background: url('../images/icon/arrow-bottom.png') no-repeat;
                                    background-size: 100% 100%;
                                }
                                &::after{
                                    display: block;
                                    content: '';
                                    width: px2rem(35.89px)!important;
                                    height: px2rem(99.24px)!important;
                                    position: absolute;
                                    bottom: px2rem(-37.47px);
                                    left: 50%;
                                    right: 0;
                                    transform: translate(-50%,-100%);
    
                                    background: url('../images/icon/arrow-left-right.png') no-repeat;
                                    background-size: 100% 100%; 
                                }
                            }
                            .grid-arrow-left {
                                width: px2rem(4px);
                                // height: 100%;
                                position: relative;
                                &::after{
                                    display: block;
                                    content: '';
                                    width: px2rem(34.06px)!important;
                                    position: absolute;
                                    left: px2rem(4px);
                                    top: px2rem(30px);
                                    bottom: px2rem(46px);
                                    background: url('../images/icon/arrow-left.png') no-repeat;
                                    background-size: 100% 100%; 
                                }
                            }
    
    
                        }
                        
                       
    
                    }
                }
    
                .item-three {
                    @include reset();
                    display: flex;
                    justify-content: center;
                    align-items: normal;
                    padding-top: px2rem(118px);
                    padding-bottom: px2rem(118px);
                    box-sizing: border-box;
    
                    .star-bg {
                        left: px2rem(-120px);
                        height: 110%;
                        width: 200%;
                        
                    }
                    .text-box {
                        font-family: PosteramaText-Regular;
                        font-size: px2rem(20px);
                        color: #FFFFFF;
                        line-height: px2rem(36px);
                        width: px2rem(348px);
                        .main-group.title{
                            font-size: px2rem(20px);
                        }
                        .main-group{
                            font-size:px2rem(20px);
                        }
                        .red {
                            font-family: PosteramaText-Regular;
                            font-size:px2rem(20px);
                            color: #D9052D;
                            line-height:px2rem(36px);
                        }
                    }
                }
                .item-four{
                    @include reset();
                    padding-top: px2rem(109px);
                    display: flex;
                    justify-content: center;
                    box-sizing: border-box;
    
                    .wrap{
                        display: flex;
                        flex-direction: column;
                        padding-top: px2rem(18px);
                        padding-bottom: px2rem(70px);
                        .content{
                            @include reset();
                            .t{
                                @include reset();
                                font-family: Posterama1927-SemiBold;
                                font-size: px2rem(50px);
                                color: #FFFFFF;
                                line-height: px2rem(68px);
                            
                                &:nth-child(2){
                                    font-size: px2rem(60px);
                                }
                            }
                            .text-box{
                                @include reset();
                                width: px2rem(348px);
                                padding-top: px2rem(14px);
                                .c{
                                    width: 100%;
                                    font-family: PosteramaText-Regular;
                                    font-size: px2rem(16px);
                                    line-height: px2rem(32px);
                                    color: #FFFFFF;
                                }
               
                            }
                        }
                        .b{
                            @include reset();
                            margin-top: px2rem(16px);
                            font-size: px2rem(28px);
                         
                            display: inline-block;
                            width: fit-content;
                            margin-top: px2rem(14px);
                            &.pc{
                                display: none;
                            }
                        }
                    }
                    .bg-p{
                        .under{
                            width: 100%;
                            background: url('../images/under_wap.png') center center;
                            background-size: 100% 100%;
                            height: px2rem(127px);
                            position: absolute;
                            left: 0;
                            right: 0;
                            bottom: 0;
                        }
                        .person{
                            left: px2rem(170px);
                            bottom: 0;
                            .c{
                                height: px2rem(228px);
                                transform: translate(px2rem(-52px),px2rem(-9px));
                            }
                            .l{
                                height: px2rem(204px);
                                margin-right: px2rem(64px);
                            }
                            .r{
                                height: px2rem(204px);
    
                            }
                        }
                    }
         
                }
            }

        }

    }
    // qiu
    .ball.container{
        .section3 {
            .ui_base.u_p3d{
                transform: scale(0.2);
                animation: none;
             }
             .ball_c{
                //         width: 189px);
                // height: 100px);
                // background-size: 100% 100%;
            }
            .ball_base.u_p3d {
                .ball{
                    animation: none;
                }
            }
        }
    
    }
    .container.ball.wap {
        width: 100%;
        overflow: hidden;
        .ball-wrap{
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            z-index: 99;
            .ball{
                z-index: 999;
                position: absolute;
                border-radius: 50%;
                transform-style:preserve-3d;
                cursor: pointer;
                &::before{
                    position: absolute;
                    content: '';
                    left: px2rem(1px);
                    right: px2rem(1px);
                    top: px2rem(1px);
                    bottom: px2rem(1px);
                    border-radius: 50%;
                    // animation: pulsate 3s 2s linear infinite;
                }
                &:nth-child(1){
                    background: url('../images/ball1.png') no-repeat center center;
                    background-size: 100% 100%;
                    top:46%;
                    left: 5%;
                    width: px2rem(80px);
                    height: px2rem(80px);
                    &::after{
                        left: px2rem(5px);
                        right: px2rem(5px);
                        top: px2rem(3px);
                        bottom: px2rem(8px);
                    }
                    &::before{
                        animation: pulsate 3s 2s linear infinite;
                    }
                }
                &:nth-child(2){
                    background: url('../images/ball2.png') no-repeat center center;
                    background-size: 100% 100%;
                    left: 68%;
                    top: 31%;                
                    width: px2rem(50px);
                    height: px2rem(50px);

                    &::before{
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        animation: pulsate 3s 0.5s linear infinite;
                    }
                }
                &:nth-child(3){
                    background: url('../images/ball3.png') no-repeat center center;
                    background-size: 100% 100%;
                    left: 97%;
                    transform: translateX(-80%);
                    width: px2rem(100px);
                    height: px2rem(100px);
                    bottom: 33%;
                    top: 52%;
                    &::before{
                        animation: pulsate 3s 1.5s linear infinite;
                    }
        
                }
                &:nth-child(4){
                    background: url('../images/ball4.png') no-repeat center center;
                    background-size: 100% 100%;
                    bottom: px2rem(39.15px);
                    left: 100%;
                    top: 64%;
                    transform: translateX(-100%);
                    width: px2rem(250px);
                    height: px2rem(230px);
                    &::before{
                        left: 50%;
                        right: 0;
                        top: 50%;
                        bottom: 0;
                        transform: translate(-50%, -50%);
                        animation: pulsate 3s 1s linear infinite;
                    }
                }
                &:nth-child(5){
                    background: url('../images/ball.png') no-repeat;
                    top: 11%;
                    left: 18%;
                    width: px2rem(210px);
                    height: px2rem(280px);
                    background-size: 89% 83%;
                    background-position: bottom;
                    cursor: auto;
                    z-index: 88;
                    &::before{
                        left: 44%;
                        top: 52%;
                        right: -8%;
                        bottom: 0;
                        transform: translate(-45%, -19%);                       
                        animation: pulsate 3s linear infinite;
                    }
        
                }  


                
     
            }
        }
      
    
    }
   
}

.App.pc{
    .wap {
        display: none !important;
    }
}
.App.mobile{
    .pc {
        display: none !important;
    }
}




  @keyframes pulsate {
    50% {
        box-shadow: 0 0 px2rem(5) #fff, px2rem(5px) 0 px2rem(10px) rgb(180, 153, 180), px2rem(-5px) 0 px2rem(10px) rgb(197, 234, 234);
        /* inset 0 0 .3rem #fff,
        inset -0.3rem 0 .2rem rgb(179, 129, 179); */
        /* inset .3rem 0 .2rem #0ff,
        inset -0.3rem 0 .4rem #d265d2,
        inset .3rem 0 .4rem rgb(0, 255, 255); */
    }
  }