@import url(./totate.css);
.container{
    width: 100%;
    height: 100%;
    // background: url('../img/allStart.jpg') no-repeat center center;
    // background-size: 100% 100%;
    position: absolute;
    overflow: hidden;
    // background: url('../../images/gui.png') no-repeat center center;
    background: url('../../images/gui_wap.png') no-repeat center center;
    background-size: 100% 100%;
}
.container.wap{
  background: url('../../images/gui_wap.png') no-repeat center center;
  background-size: 100% 100%;
}
.container.pc{
  background: url('../../images/gui.png') no-repeat 40% 10%;
  background-size: 140% 130%;
}




  .container.ball.pc {
    width: 100%;
    overflow: hidden;
    .ball-wrap{
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 99;
        .ball{
            z-index: 999;
            position: absolute;
            border-radius: 50%;
            transform-style:preserve-3d;
            cursor: pointer;
            animation: scaleChange 3s linear infinite;
            &::before{
                position: absolute;
                content: '';
                left: 0.01rem;
                right:0.01rem;
                top:0.01rem;
                bottom:0.01rem;
                border-radius: 50%;
                animation: pulsatePc 3s linear infinite;
            }
            &:nth-child(1){
                background: url('../../images/ball1.png') no-repeat center center;
                background-size: 100% 100%;
                top:56%;
                left: 20%;
                width: 1.3rem;
                height: 1.3rem;
                &::after{
                    left:0.05rem;
                    right:0.05rem;
                    top: 0.03rem;
                    bottom:0.08rem;
                }
                &::before{
                    animation: pulsatePC 3s 2s linear infinite;
                }
            }
            &:nth-child(2){
                background: url('../../images/ball2.png') no-repeat center center;
                background-size: 100% 100%;               
                width: .6rem;
                height: .6rem;
                top: 32%;
                left: 57%;
                &::before{
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    animation: pulsatePC 3s .5s linear infinite;
                }
            }
            &:nth-child(3){
                background: url('../../images/ball3_1.png') no-repeat center center;
                background-size: 100% 100%;
                left: 97%;
                width: .9rem;
                height: .9rem;
                left: 48%;
                top: 50%;
                &::before{
                    animation: pulsatePC 3s 1.5s linear infinite;
                }
    
            }
            &:nth-child(4){
                background: url('../../images/ball4.png') no-repeat center center;
                background-size: 100% 100%;
                bottom: 0.39rem;
                width: 335px;
                height: 315px;
                top: 54%;
                left: 74%;
                &::before{
                    left: 50%;
                    right: 0;
                    top: 50%;
                    bottom: 0;
                    transform: translate(-50%, -50%);
                    animation: pulsatePC 3s 1s linear infinite;
                }
            }
            &:nth-child(5){
                background: url('../../images/ball.png') no-repeat;
                background-size: 89% 81%;
                width: 3.27rem;
                height: 4.31rem;
                top: 4%;
                left: 41%;            
                background-position: bottom;
                cursor: auto;
                z-index: 88;
                &::before{
                    left: 44%;
                    top: 52%;
                    right: -8%;
                    bottom: 0;
                    transform: translate(-45%, -19%);                       
                    animation: pulsatePC 3s linear infinite;
                }
    
            }  


            
 
        }
    }
  
}

@keyframes scaleChange{
  0%{
    transform: scale(1);
  }
  50%{
    transform: scale(1.05);
  }
  10%{
    transform: scale(1);
  }
}


@keyframes pulsatePC {
  50% {
      box-shadow: 0 0 0.1rem #fff, 0.2rem 0 0.6rem rgb(180, 153, 180), -0.1rem 0 0.15rem rgb(197, 234, 234);
      /* inset 0 0 .3rem #fff,
      inset -0.3rem 0 .2rem rgb(179, 129, 179); */
      /* inset .3rem 0 .2rem #0ff,
      inset -0.3rem 0 .4rem #d265d2,
      inset .3rem 0 .4rem rgb(0, 255, 255); */
  }
}
