/**
 * @author Lenovo dzd
 * @date 2021/1/20 16:45
 */

/*旋转部分、、、、、、、、、、、、、、、、、、、、、、、、、、*/
#content {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
}
.section3 {
    position: absolute;
    left: 54%;
    top: 50%;
    transform: translate(-50%, -50%) scale(1.8);
    z-index: 999;
}

.section3 .ui_base {
    /* 中间容器 */
    /*width: 20.24rem;*/
    -webkit-perspective: 22.6rem;
    -moz-perspective: 22.6rem;
    -ms-perspective: 22.6rem;
    -o-perspective: 22.6rem;
    perspective: 22.6rem;
    -webkit-perspective-origin: 50% 25%;
    -moz-perspective-origin: 50% 25%;
    -o-perspective-origin: 50% 25%;
    -ms-perspective-origin: 50% 25%;
    perspective-origin: 50% 25%;
}

/* 中间圆球位置 */
.section3 .ball_c {
    -webkit-transform-origin: 50% 50%;
    -moz-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    -o-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    position: absolute;
    width: 3rem;
    height: 3rem;
    background: url('../../images/ball.png') no-repeat center center;
    background-size: 85% 100%;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
    border-radius: 50%; /* 加此属性为了解决图片所带阴影背景 */
}

/* 外侧轨道线 */
.section3 .base {
    -webkit-animation: cir 25s linear 0s infinite;
    -o-animation: cir 25s linear 0s infinite;
    -ms-animation: cir 25s linear 0s infinite;
    -moz-animation: cir 25s linear 0s infinite;
    animation: cir 25s linear 0s infinite;
    border: 0.01rem solid #ccc;
    border-radius: 50%;
    position: relative;
}
/* 外侧轨道线 */
.u_p3d {
    -webkit-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    -o-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
}
/* 轨道位置 */
.section3 .base {
    -webkit-transform: rotateX(80deg) rotateY(-10deg);
    -moz-transform: rotateX(80deg) rotateY(-10deg);
    -o-transform: rotateX(80deg) rotateY(-10deg);
    -ms-transform: rotateX(80deg) rotateY(-10deg);
    transform: rotateX(80deg) rotateY(-10deg);
    position: relative;
    width: 10rem;
    height: 10rem;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    top: -0.5rem;
    left: -0.5rem;
}
/* 轨迹线2 */
.line1 {
    width: 8.5rem;
    height: 8.5rem;
    border: 0.01rem solid #ccc;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 1;
}
/* 轨迹线3 */
.line2 {
    width: 7rem;
    height: 7rem;
    border: 0.01rem solid #ccc;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 1;
}

/* 小圆球  容器 ball_base*/
.section3 .ball_base {
    -webkit-transform-origin: 1.8rem 0rem;
    -moz-transform-origin: 1.8rem 0rem;
    -ms-transform-origin: 1.8rem 0rem;
    -o-transform-origin: 1.8rem 0rem;
    transform-origin: 1.8rem 0rem;
    width: 1rem;
    height: 1rem;
    position: absolute;
    cursor: pointer;
    z-index: 10;
}
.section3 .ball_base .ball {
    width: 1rem;
    height: 1rem;
    cursor: pointer;
}

.section3 .ball_1 {
    /*  ____ 外侧容器 */
    -webkit-transform: rotateX(-86deg) rotateY(14deg) translate(0, -50%);
    -o-transform: rotateX(-86deg) rotateY(14deg) translate(0, -50%);
    -moz-transform: rotateX(-86deg) rotateY(14deg) translate(0, -50%);
    -ms-transform: rotateX(-86deg) rotateY(14deg) translate(0, -50%);
    transform: rotateX(-86deg) rotateY(14deg) translate(0, -50%);
    position: absolute;
    left: 87%;
    top: 56%;
}

.section3 .ball_2 {
    /*  ____ 外侧容器 */
    -webkit-transform: rotateX(-90deg) rotateY(60deg) translateY(-0.7rem);
    -moz-transform: rotateX(-90deg) rotateY(60deg) translateY(-0.7rem);
    -o-transform: rotateX(-90deg) rotateY(60deg) translateY(-0.7rem);
    -ms-transform: rotateX(-90deg) rotateY(60deg) translateY(-0.7rem);
    transform: rotateX(-90deg) rotateY(60deg) translateY(-0.7rem);
    position: absolute;
    top: 50%;
    /*right: 0%;*/
}

.section3 .ball_3 {
    /*  ____ 外侧容器 */
    -webkit-transform: rotateX(-90deg) rotateY(120deg) translateY(-0.7rem);
    -o-transform: rotateX(-90deg) rotateY(120deg) translateY(-0.7rem);
    -ms-transform: rotateX(-90deg) rotateY(120deg) translateY(-0.7rem);
    -moz-transform: rotateX(-90deg) rotateY(120deg) translateY(-0.7rem);
    transform: rotateX(-90deg) rotateY(120deg) translateY(-0.7rem);
    position: absolute;
    top: 6%;
    left: 52%;
}

.section3 .ball_4 {
    /*  ____ 外侧容器  */
    -webkit-transform: rotateX(-90deg) rotateY(180deg) translateY(-0.7rem);
    -moz-transform: rotateX(-90deg) rotateY(180deg) translateY(-0.7rem);
    -ms-transform: rotateX(-90deg) rotateY(180deg) translateY(-0.7rem);
    -o-transform: rotateX(-90deg) rotateY(180deg) translateY(-0.7rem);
    transform: rotateX(-90deg) rotateY(180deg) translateY(-0.7rem);
    position: absolute;
    bottom: 0;
    left: 20%;
}

.section3 .ball_1 .ball {
    -webkit-transform: rotateY(10deg) rotateZ(10deg);
    -moz-transform: rotateY(10deg) rotateZ(10deg);
    -o-transform: rotateY(10deg) rotateZ(10deg);
    -ms-transform: rotateY(10deg) rotateZ(10deg);
    transform: rotateY(10deg) rotateZ(10deg);
}

.section3 .ball_2 .ball {
    -webkit-transform: rotateY(-50deg) rotateZ(10deg);
    -o-transform: rotateY(-50deg) rotateZ(10deg);
    -ms-transform: rotateY(-50deg) rotateZ(10deg);
    -moz-transform: rotateY(-50deg) rotateZ(10deg);
    transform: rotateY(-50deg) rotateZ(10deg);
}

.section3 .ball_3 .ball {
    -webkit-transform: rotateY(-110deg) rotateZ(10deg);
    -o-transform: rotateY(-110deg) rotateZ(10deg);
    -moz-transform: rotateY(-110deg) rotateZ(10deg);
    -ms-transform: rotateY(-110deg) rotateZ(10deg);
    transform: rotateY(-110deg) rotateZ(10deg);
}

.section3 .ball_4 .ball {
    -webkit-transform: rotateY(-170deg) rotateZ(10deg);
    -o-transform: rotateY(-170deg) rotateZ(10deg);
    -moz-transform: rotateY(-170deg) rotateZ(10deg);
    -ms-transform: rotateY(-170deg) rotateZ(10deg);
    transform: rotateY(-170deg) rotateZ(10deg);
}

.section3 .ball {
    -webkit-transition: all 2s ease-out 0ms;
    -o-transition: all 2s ease-out 0ms;
    -moz-transition: all 2s ease-out 0ms;
    -ms-transition: all 2s ease-out 0ms;
    transition: all 2s ease-out 0ms;
    -webkit-transform-origin: 50% 50%;
    -o-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    -moz-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    /* background: url('../../images/bal3l1.png') no-repeat center center; */
    background-size: 100% 100%;
    text-align: center;
    width: 1rem;
    height: 1rem;
}

.section3 .ball_1 .ball {
    -webkit-transition-delay: 1100ms;
    -o-transition-delay: 1100ms;
    -moz-transition-delay: 1100ms;
    -ms-transition-delay: 1100ms;
    transition-delay: 1100ms;
    background-image: url('../../images/ball1.png');
    width: 1.48rem;
    height: 1.48rem;
    background-size: 100% 100%;
}

.section3 .ball_2 .ball {
    -webkit-transition-delay: 900ms;
    -moz-transition-delay: 900ms;
    -o-transition-delay: 900ms;
    -ms-transition-delay: 900ms;
    transition-delay: 900ms;
    width: 1.48rem;
    height: 1.48rem;
    background: url('../../images/ball2.png') no-repeat center center;
    background-size: 100% 100%;

}

.section3 .ball_3 .ball {
    -webkit-transition-delay: 700ms;
    -o-transition-delay: 700ms;
    -ms-transition-delay: 700ms;
    -moz-transition-delay: 700ms;
    transition-delay: 700ms;
    background-image: url('../../images/ball3.png');

    /* background-size: auto; */
}

.section3 .ball_4 .ball {
    -webkit-transition-delay: 500ms;
    -o-transition-delay: 500ms;
    -ms-transition-delay: 500ms;
    -moz-transition-delay: 500ms;
    transition-delay: 500ms;
    background-image: url('../../images/ball4.png');
}

.section3 .ball_1 .ball {
    -webkit-animation: cir1 25s linear 0s infinite;
    -o-animation: cir1 25s linear 0s infinite;
    -ms-animation: cir1 25s linear 0s infinite;
    -moz-animation: cir1 25s linear 0s infinite;
    animation: cir1 25s linear 0s infinite;
}

.section3 .ball_2 .ball {
    -webkit-animation: cir2 24s linear 0s infinite;
    -o-animation: cir2 25s linear 0s infinite;
    -ms-animation: cir2 25s linear 0s infinite;
    -moz-animation: cir2 25s linear 0s infinite;
    animation: cir2 25s linear 0s infinite;
}

.section3 .ball_3 .ball {
    -webkit-animation: cir3 23s linear 0s infinite;
    -o-animation: cir3 25s linear 0s infinite;
    -ms-animation: cir3 25s linear 0s infinite;
    -moz-animation: cir3 25s linear 0s infinite;
    animation: cir3 25s linear 0s infinite;
}

.section3 .ball_4 .ball {
    -webkit-animation: cir4 22s linear 0s infinite;
    -o-animation: cir4 25s linear 0s infinite;
    -ms-animation: cir4 25s linear 0s infinite;
    -moz-animation: cir4 25s linear 0s infinite;
    animation: cir4 25s linear 0s infinite;
}

@-webkit-keyframes cir1 {
    0% {
        -webkit-transform: rotateY(10deg) rotateZ(10deg);
    }
    100% {
        -webkit-transform: rotateY(-360deg) rotateZ(10deg);
    }
}

@keyframes cir1 {
    0% {
        transform: rotateY(10deg) rotateZ(10deg);
    }
    100% {
        transform: rotateY(-360deg) rotateZ(10deg);
    }
}

@-webkit-keyframes cir2 {
    0% {
        -webkit-transform: rotateY(-60deg) rotateZ(10deg);
    }
    100% {
        -webkit-transform: rotateY(-420deg) rotateZ(10deg);
    }
}

@keyframes cir2 {
    0% {
        transform: rotateY(-60deg) rotateZ(10deg);
    }
    100% {
        transform: rotateY(-420deg) rotateZ(10deg);
    }
}

@-webkit-keyframes cir3 {
    0% {
        -webkit-transform: rotateY(-120deg) rotateZ(10deg);
    }
    100% {
        -webkit-transform: rotateY(-480deg) rotateZ(10deg);
    }
}

@keyframes cir3 {
    0% {
        transform: rotateY(-120deg) rotateZ(10deg);
    }
    100% {
        transform: rotateY(-480deg) rotateZ(10deg);
    }
}

@-webkit-keyframes cir4 {
    0% {
        -webkit-transform: rotateY(-180deg) rotateZ(10deg);
    }
    100% {
        -webkit-transform: rotateY(-540deg) rotateZ(10deg);
    }
}

@keyframes cir4 {
    0% {
        transform: rotateY(-180deg) rotateZ(10deg);
    }
    100% {
        transform: rotateY(-540deg) rotateZ(10deg);
    }
}

@keyframes cir {
    0% {
        transform: rotateX(80deg) rotateY(-10deg) rotateZ(0deg);
    }
    100% {
        transform: rotateX(80deg) rotateY(-10deg) rotateZ(-360deg);
    }
}

@-webkit-keyframes cir {
    0% {
        -webkit-transform: rotateX(80deg) rotateY(-10deg) rotateZ(0deg);
    }
    100% {
        -webkit-transform: rotateX(80deg) rotateY(-10deg) rotateZ(-360deg);
    }
}

@keyframes pulsate {
    50% {
        box-shadow: 0 0 0.05rem #fff, 0.05rem 0 0.1rem rgb(180, 153, 180), -0.05rem 0 0.1rem rgb(197, 234, 234);
        /* inset 0 0 .3rem #fff,
        inset -0.3rem 0 .2rem rgb(179, 129, 179); */
        /* inset .3rem 0 .2rem #0ff,
        inset -0.3rem 0 .4rem #d265d2,
        inset .3rem 0 .4rem rgb(0, 255, 255); */
    }
}


.section3 .ball_base .ball {
    position: relative;
    cursor: pointer;
}
.section3 .ball_base .ball::before {
    position: absolute;
    content: '';
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 50%;
    animation: pulsate 6s linear infinite;
}
.section3 .ball_1 .ball::before {
    top: 0.17rem;
    bottom: 0.22rem;
    left: 0.12rem;
    right: 0.18rem;

}

.section3 .ball_2 .ball::before {
    right: 0.24rem;
    left: 0.23rem;
    bottom: 0.24rem;
    top: 0.24rem;
}
.section3 .ball_3 .ball::before {
    right: 0.03rem;
    left: 0.11rem;
    bottom: 0.1rem;
    top: 0.1rem;
}
