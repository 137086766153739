$base_width: 1920;
$base_fontSize: 96;

@font-face {
	font-family: "Posterama1927-Black";
	src: url("../css/font/Posterama/4a633a8efda614b0de398f042be3a396.eot"); /* IE9*/
	src: url("../css/font/Posterama/4a633a8efda614b0de398f042be3a396.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
	url("../css/font/Posterama/4a633a8efda614b0de398f042be3a396.woff2") format("woff2"), /* chrome、firefox */
	url("../css/font/Posterama/4a633a8efda614b0de398f042be3a396.woff") format("woff"), /* chrome、firefox */
	url("../css/font/Posterama/4a633a8efda614b0de398f042be3a396.ttf") format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
	url("../css/font/Posterama/4a633a8efda614b0de398f042be3a396.svg#Posterama1927-Black") format("svg"); /* iOS 4.1- */
  }
@font-face {font-family: "PosteramaText-SemiBold";
	src: url("../css/font/SemiBold/55baa1d6961f56c57cda7ce1bf41b6e7.eot"); /* IE9*/
	src: url("../css/font/SemiBold/55baa1d6961f56c57cda7ce1bf41b6e7.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
	url("../css/font/SemiBold/55baa1d6961f56c57cda7ce1bf41b6e7.woff2") format("woff2"), /* chrome、firefox */
	url("../css/font/SemiBold/55baa1d6961f56c57cda7ce1bf41b6e7.woff") format("woff"), /* chrome、firefox */
	url("../css/font/SemiBold/55baa1d6961f56c57cda7ce1bf41b6e7.ttf") format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
	url("../css/font/SemiBold/55baa1d6961f56c57cda7ce1bf41b6e7.svg#PosteramaText-SemiBold") format("svg"); /* iOS 4.1- */
  }
@font-face {font-family: "PosteramaText-Regular";
	src: url("../css/font/Regular/a473f32ddaf0ac9ecbafb5cb7c0ba67e.eot"); /* IE9*/
	src: url("../css/font/Regular/a473f32ddaf0ac9ecbafb5cb7c0ba67e.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
	url("../css/font/Regular/a473f32ddaf0ac9ecbafb5cb7c0ba67e.woff2") format("woff2"), /* chrome、firefox */
	url("../css/font/Regular/a473f32ddaf0ac9ecbafb5cb7c0ba67e.woff") format("woff"), /* chrome、firefox */
	url("../css/font/Regular/a473f32ddaf0ac9ecbafb5cb7c0ba67e.ttf") format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
	url("../css/font/Regular/a473f32ddaf0ac9ecbafb5cb7c0ba67e.svg#PosteramaText-Regular") format("svg"); /* iOS 4.1- */
  }


  