.whole-page {
    width: 100%;
    height: 100%;
    position: relative;
    opacity: 1;
    transition: all 0.5s;
    .lef-nav-box,
    .right-nav-box {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 999;
        cursor: pointer;
        .nav-item {
            background-size: 100% 100%;
        }
    }

    // 导航
    .lef-nav-box {
        left: .7rem;

        .nav-item {
            width: .85rem;
            height: .85rem;
            margin-top: .4rem;

            &:hover {
                &:nth-child(1) {
                    background-image: url('../../images/icon/home-active.png');
                }

                &:nth-child(2) {
                    background-image: url('../../images/icon/twitter-active.png');
                }

                &:nth-child(3) {
                    background-image: url('../../images/icon/voice-active.png');
                }
            }

            &:nth-child(1) {
                background-image: url('../../images/icon/home.png');
            }

            &:nth-child(2) {
                background-image: url('../../images/icon/twitter.png');
            }

            &:nth-child(3) {
                background-image: url('../../images/icon/voice.png');
            }

        }

        .nav-item.closed {
            background-image: url('../../images/icon/voice-close.png');

            &:hover {
                background-image: url('../../images/icon/voice-close-active.png');
            }
        }
    }

    .right-nav-box {
        right: .7rem;

        .nav-item {
            width: .85rem;
            height: .85rem;
            margin-top: .7rem;

            &:hover {
                &:nth-child(1) {
                    background-image: url('../../images/icon/last-active.png');
                }

                &:nth-child(2) {
                    background-image: url('../../images/icon/next-active.png');
                }

            }

            &:nth-child(1) {
                background-image: url('../../images/icon/last.png');
            }

            &:nth-child(2) {
                background-image: url('../../images/icon/next.png');
            }

        }
    }

    .content-item {
        width: 100%;
        height: 100%;
        // background-color: #D9052D;
        display: flex;
        flex-direction: column;

        .logo-icon-small {
            position: absolute;
            top: .21rem;
            width: 1.0012rem;
            height: .48rem;
        }

        .main-view {
            // background-color: #D9052D;
            flex: 1;

            &>div {
                position: relative;
            }

            .item-one {
                height: 100%;
                display: flex;
                align-items: center;
                // justify-content: center;
                padding-left: 3.56rem;
                position: relative;
                .logo-icon-small{
                    position: absolute;
                    left: 3.56rem;
                    top: .21rem;
                }
                .wrap {
                    display: inline-block;
                    position: relative;

                    .bg {
                        width: 8.75rem;
                        height: auto;
                        position: absolute;
                        left: 0;

                    }

                    .so {
                        font-family: PosteramaText-SemiBold;
                        font-size: 1.2rem;
                        color: #FFFFFF;
                        display: flex;
                        align-items: flex-end;
                        margin-right: 3.77rem;
                    }

                    .the {
                        padding-top: 2.11rem;
                        width: 6.82rem;
                        line-height: 0.32rem;
                        font-family: PosteramaText-Regular;
                        font-size: .24rem;
                        color: #FFFFFF;
                    }

                    .here {
                        font-family: PosteramaText-SemiBold;
                        font-size: .8rem;
                        color: #FFFFFF;
                        padding-top: .42rem;
                        line-height: .7rem;
                        margin-right: 3.77rem;
                        display: flex;
                    }

                    .red-text {
                        padding-top: .21rem;
                        font-family: PosteramaText-SemiBold;
                        font-size: .8rem;
                        color: #D9052D;
                        line-height: .7rem;
                        text-align: left;
                        padding-left: 1.48rem;
                    }
                }

            }

            .item-two {
                height: 100%;
                display: flex;
                align-items: center;
                padding-left: 3.59rem;
                position: relative;
                .logo-icon-small{
                    left: 3.59rem;
                    top: .21rem;
                    position: absolute;
                }
                .box {
                    display: flex;

                    .left {
                        width: 6.2rem;
                        position: relative;
                        margin-right: .43rem;
                        display: flex;
                        flex-direction: column;

                        .dou {
                            // opacity: 0.5;
                            font-family: PosteramaText-SemiBold;
                            font-size: 3rem;
                            color: #D9052D;
                            position: absolute;
                            top: 45%;
                            left: -0.3rem;
                            z-index: 1;
                            opacity: 0.5;
                            width: 1.35rem;
                        }
                        .dou-tex{
                            position: relative;
                            z-index: 2;
                        }

                        .the {
                            font-family: PosteramaText-Regular;
                            font-size: .5rem;
                            color: #FFFFFF;
                            line-height: .7rem;
                            margin-bottom: .3rem;
                            position: relative;
                        }

                        .it {
                            font-family: PosteramaText-SemiBold;
                            font-size: .8rem;
                            color: #FFFFFF;
                            line-height: .9rem;
                        }
                    }

                    .grid-box.pc {
                        height: fit-content;

                        .right {
                            .grid-border {

                                background-image: linear-gradient(#2D2D2D, #FFFFFF);
                                border-radius: .17rem;
                                padding: .02rem;

                                .grid {
                                    font-family: PosteramaText-Regular;
                                    font-size: .14rem;
                                    color: #FFFFFF;
                                    width: 1.58rem;
                                    padding: .02rem;
                                    border-radius: .17rem;

                                    background-color: #1a1a1d;
                                    padding: .2rem;
                                }

                                &.end {
                                    margin-top: .44rem;
                                    position: relative;

                                    .grid-arrow-spec {
                                        position: absolute;
                                        width: .64rem;
                                        height: 1.775rem;
                                        bottom: 50%;
                                        left: -.675rem;

                                        img {
                                            width: 100%;
                                            transform: translateX(-0.01rem);
                                        }

                                    }

                                }

                            }

                            .grid-arrow {
                                height: .63rem;
                                width: 100%;
                                background-image: url('../../images/icon/arrow-bottom.png');
                                background-size: .34rem .14rem;
                                background-repeat: no-repeat;
                                background-position: center center;
                            }
                        }

                        .right.one {
                            margin-right: .74rem;

                            .grid-border:nth-child(1) {
                                position: relative;

                                &::after {
                                    position: absolute;
                                    content: '';
                                    display: block;
                                    top: 0;
                                    bottom: 0;
                                    width: .74rem;
                                    right: -0.74rem;
                                    background-image: url('../../images/icon/arrow-bottom.png');
                                    background-size: .34rem .14rem;
                                    background-repeat: no-repeat;
                                    background-position: center center;
                                    transform: rotate(-90deg);
                                }
                            }

                        }

                        .grid-arrow-left {
                            width: .61rem;
                            display: flex;
                            align-items: center;
                            margin-left: .06rem;
                            img {
                                width: 100%;
                                transform: translateY(-0.14rem);
                                height: 5.8531rem;
                            }

                        }

                    }


                }







            }

            .item-three {
                display: flex;
                align-items: center;
                height: 100%;
                padding-left: 3.59rem;
                position: relative;
                overflow: hidden;
                .logo-icon-small{
                    left: 3.59rem;
                    top: .21rem;
                    position: absolute;
                }
                .text-box {
                    .main-group{
                        font-size: 0.3rem;
                    }
                    .main-group.title{
                        font-size: .45rem;
                    }
                    width: 11.58rem;
                    font-family: PosteramaText-Regular;
                    font-size: .45rem;
                    color: #FFFFFF;
                    line-height: .7rem;

                    .red {
                        font-family: PosteramaText-Regular;
                        font-size: .45rem;
                        color: #D9052D;
                        line-height: .7rem;
                    }
                }

                .star-bg {
                    height: 100%;
                    position: absolute;
                    right: 0;
                    top: 0;
                    bottom: 0;

                }
            }

            .item-four {
                height: 100%;
                padding-left: 3.57rem;
                position: relative;
                .logo-icon-small{
                    left: 3.57rem;
                    top: .21rem;
                    position: absolute;
                }
                .wrap {
                    display: flex;
                    height: 100%;
                    .content {
                        padding-top: 2rem;

                        .t {
                            text-transform:uppercase;
                            font-family: PosteramaText-SemiBold;
                            font-size: 1.1rem;
                            color: #FFFFFF;
                            line-height: .9rem;
                            margin-bottom: .66rem;
                        }
                        .person-box{
                            width: 5.4rem;
                        }
                    }
                }

                .text-box {
                    display: flex;
                    padding-right: 3.87rem;

                    .c {
                        font-family: PosteramaText-Regular;
                        font-size: .24rem;
                        color: #FFFFFF;
                        width: 6.96rem;
                        line-height: 0.32rem;

                        .b {
                            font-family: PosteramaText-Regular;
                            font-size: .4rem;
                            color: #FFFFFF;
                            margin-top: .16rem;
                        }
                    }

                }

                .bg-p {
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    z-index: 777;
                    .under{
                        width: 100%;
                        height: 2.25rem;
                        background: url('../../images/under.png') center center;
                        background-size: 100% 100%;
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: 0;
                    }
              
                }
                .person {
                    position: absolute;
                    left: 10.93rem;
                    bottom: 1.47rem;
                    .c {
                        height: 4.51rem;
                        width: auto;
                        z-index: 790;
                        left: 50%;
                        transform: translate(-50%,-0.19rem);
                    }

                    .l {

                        height: 4.08rem;
                        width: auto;
                        z-index: 789;
                        margin-right: 1.28rem;
                    }

                    .r {

                        height: 4.43rem;
                        width: auto;
                        z-index: 787;

                    }
                }

            }
        }

    }

}


// 打字
/* 边框闪烁动画 模拟指针闪烁 */	
@keyframes broders {
    from {border-right-color: #000;}
    to {border-right-color: #fff;}
}
/* 容器宽的改变动画 */
@keyframes widths {
    from {width: 0;}
    to {width: 6.2rem
    } /* 也可以是固定宽 */
}
.line {
    // border-right: 8.33px solid #000;
    font-size: 200%; /* 字体大小 */
    font-family: monospace; /* 等宽字体 */
    text-align: center; /* 文字横向居中 */
    white-space: nowrap; /* 文字不换行 */
    overflow: hidden; /* 多余的文字内容隐藏 */
    // animation: borders 0.7s infinite normal,
    // widths 2s steps(14); /* 重点 steps() */   
    animation: widths 2s steps(14); /* 重点 steps() */
}


.dou.animate__animated{
    // animation-fill-mode: forwards;
}



// 地面展示



/* 容器宽的改变动画 */
@keyframes widths {
    from {width: 0;}
    to {width: 100%;} /* 也可以是固定宽 */
}
// person
@keyframes shwo {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}


.under{
    width: 100%;
    // animation: widths 2s;
    height: 2.25rem;
}
.person{
    >img{
        opacity: 0;
        animation: shwo 2s;
        animation-fill-mode: forwards;
        &:nth-child(1){
            animation-delay: 0.5s;
            position: absolute;
        }
        &:nth-child(2){
            animation-delay: 1s;
        }
        &:nth-child(3){
            animation-delay: 1.4s;
        }
    }
}


//红标文字动画


.b.animated {
    position: relative;
    display: inline-block;
    padding: 0 .03rem;
    z-index: 1;
    >span {
      content: "";
      display: inline-block;
      left: 0;
      height: 28%;
      bottom: 0;
      position: absolute;
      // background-color: #ff1053;
      z-index: -1;
      animation: toRight 1s;
      animation-fill-mode: forwards;
    }
  }
  @keyframes toRight {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }