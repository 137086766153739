.home {
    width: 100%;
    height: 100%;
    position: relative;
    background-color: #19191d;
}


.round-box {
    position: fixed;
    z-index: 2;
    display: flex;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    .round-img {
        width: 1.8rem;
        opacity: 1;
        animation: 3s redRound;
        animation-fill-mode: forwards;
    }
}


@keyframes blackRound {
    0% {
        width: 2.4rem;
        opacity: 0;
    }

    20% {
        width: 3.8rem;
        opacity: 1;
    }

    80% {
        width: 4.8rem;
        opacity: 0.9;
    }

    100% {
        width: 6rem;
        opacity: 0;
    }
}

@keyframes redRound {
    0% {
        opacity: 1;
    }

    40% {
        opacity: 1;
    }

    90% {
        opacity: 0.9;
    }

    100% {
        opacity: 0;
    }
}






