
ul{
	list-style:none;
}
.hide{
    display: none;
}
.lf{
	float: left;
}
.lr{
	float: right;
}
.red, .red a,.red a h5, .red a p{
    color:#FF0000 !important;
}
a {
    text-decoration: none;
    color: inherit;
}
.mr2 {
	margin-right: 1.2rem;
}
.ml2 {
	margin-left: 1.2rem;
}

.main-group{
	// box-sizing: border-box;
	// max-width:8.3333rem;
	// overflow: hidden;
	// height:100vh;
	// padding:0 5em;
	display: flex;
	align-items: center;
	// margin: 0 auto;
}
.main-group .text{
	color: #fff;
	font-size: 1em;
}
.main-group .text span,.main-group .text .red{
	position: relative;
	display:inline-block;
	cursor: pointer;
}
.main-group .text span.action{
	animation: smoke 2s linear forwards;
	transform-origin: bottom;
}


@keyframes  smoke{
	0%{
		opacity: 1;
		filter: blur(0);
		transform: translateX(0) translateY(0) rotate(0deg) scale(1);
	}
	50%{
		opacity: 1;
		pointer-events:none;
	}
	100%{
		opacity: 0;
		filter: blur(1rem);
		transform: translateX(15rem) translateY(-15rem) rotate(720deg) scale(4);
	}


}