@import url('/src/css/normalize.css');
@import url('/src/css/_mix.scss');



@function rem($px){
  @return $px / $base_fontSize * 100px;
}




ul {
	list-style: none;
	margin: 0;
	padding: 0;
	li {
		margin: 0; padding: 0;
	} 
}

ul {
	list-style: none;
	margin: 0;
	padding: 0;
	li {
		margin: 0; padding: 0;
	} 
}
a, p, h1,h2,h3,h4,h5, body {
	color: #fff; 
	text-decoration: none;
	// font-weight: 300;
	// margin: 0;
	// font-size: 1.05em;
}
a {
	display: block;
	letter-spacing: .2em;
}

h1 {
	// color: $threat-red;
	font-size: 2.3em;
	letter-spacing: .2em;
	margin-bottom: .625rem;
}

h2 {
	font-size: 4.35em;
	line-height: 1em;
	color: #fff;
	letter-spacing: .05em;
}



html,body{
  height: 100%;
  width:100%;
  min-width:1200px; // 可自行调整最小宽度
}
#root{
  height: 100%;
}
.App {
  height: 100%;
  background-color: #1a1a1d;
  color: white;
  font-style:normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: .002rem;
  -moz-osx-font-smoothing: grayscale;
  cursor: auto;
}


.flex{
  display: flex;
}

.flex-column{
  display: flex;
  flex-direction: column;
}
.flex-center{
  align-items: center;
  justify-content: center;
}

.flex-1{
	flex: 1;
}


  .scratchy {
    background: url("../src/images/repeat-white.png") center center repeat;
    background-size: .8rem .6rem;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-animation: scratchy 0.253s linear forwards infinite;
    -moz-animation: scratchy 0.253s linear forwards infinite;
    animation: scratchy 0.253s linear forwards infinite;
}

.mock{
	transform: translate(10px);
}


